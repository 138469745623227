<template>
    <v-layout 
        class="lay-root" 
        v-ripple="!readOnly"
        align-center
        justify-center
        :class="[
            `thema-${thema}`,
            readOnly? `read-only` : ``
        ]"
        :style="{
            marginTop: marginTop,
            marginBottom: marginBottom,
            marginLeft: marginLeft,
            marginRight: marginRight,
            padding: paddingSel,
            width: typeof width == 'number' ?  width : width,
            maxWidth: typeof width == 'number' ?  width : width,
            height: height,
            borderRadius: borderRadius
        }"
        @click.prevent.stop="onClick && !readOnly ? onClick() : ''"
    >
            <slot name="inside-left"/>
            <span class="btn-txt" :style="{ fontSize: fontSize, fontWeight: fontWeight}" v-html="txtBtn"></span>
            <slot name="inside-right"/>
    </v-layout>
</template>
<script>

export default {
    components: {
    },
    props: {
        focus:{
            default:()=>{}
        },
        blur:{
            default:()=>{}
        },
        thema: {
            default: 'default'
        },
        txtBtn: {
            default: '더보기',
        },
        marginTop: {
            default: 0
        },
        marginBottom: {
            default: 0
        },
        marginLeft: {
            default: 0
        },
        marginRight: {
            default: 0
        },
        paddingSel: {
            default: '',
        },
        onClick: {
            default: ()=>{ }
        },
        readOnly: {
            default: false
        },
        fontSize: {
            default: 22
        },
        fontWeight: {
            default: 'bold'
        },
        width: {
            default: null
        },
        height: {
            default: 48
        },
        borderRadius: {
            default: 8
        }
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    destroyed() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";
.lay-root {
    height: 48px;
    box-sizing: border-box;
    color: #dadae5;
    cursor: pointer;
    .btn-txt{
        margin: 0 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &.thema-default {
        background-color: rgba(33, 28, 80, 0.8);
        border: 1px solid rgba(255, 255, 255, 0.5);
        color: white;
    }
    &.thema-gray {
        background-color: #EFEFEF;
        color: #1F1F1F;
    }
    &.thema-yellow-bg {
        background-color: #fac449 !important;
        color: #1a1a1a !important;
    }
    &.thema-white-bg {
        font-weight: 500;
        border: 1px solid #dfdfdf;
        background-color: #fff;
        color: #1a1a1a;
    }
    &.thema-black-border{
        border: 1px solid #1f1f1f;
        color: #1f1f1f;
    }
    &.thema-blue-bg{
        background-color: #4559f7;
        color: #efefef;
    }
    
    &.thema-open{
        background-color: #efefef;
        color: #4559f7;
    }

    &.thema-soldOut{
        background-color: #777;
        color: #efefef;
    }

    &.thema-change{
        background-color: #1f1f1f;
        border: 1px solid #c8c8c8;
        color: #c8c8c8;
    }

    &.thema-grey-bg{
        background-color: #1f1f1f;
        border: 1px solid #2a2a2a;
        color: #c8c8c8;
    }
    &.thema-black-bg{
        background: #1A1A1A;
        border: 1px solid #2A2A2A;
        color: #FFFFFF;
    }
    &.thema-lavender{
        background: #4559F7;
        color: #FFFFFF;
    }
}

</style>